import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import {
  Auth0Provider,
  withAuthenticationRequired,
  useAuth0,
} from "@auth0/auth0-react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Loader from "./Loader";
import Login from "./Login";
import Home from "./home/Home";

const Track = React.lazy(() => import("./track/Track"));
const Packages = React.lazy(() => import("./packages/Packages"));

const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#34545a",
    },
    secondary: {
      main: "#ffc37f",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <BrowserRouter>
          <Auth0ProviderWithRedirectCallback
            domain={
              process.env.REACT_APP_ENV === "prod"
                ? "auth.shiprec.io"
                : "shiprecdev.us.auth0.com"
            }
            clientId={
              process.env.REACT_APP_ENV === "prod"
                ? "oYSHgF0Qz3YuUr3Cb9MHi0WAwaJF4Fr2"
                : "AsNN5l00Dra6jeTKH7rjj6MpbQe9wzuR"
            }
            redirectUri={`${window.location.origin}/?route=packages`}
            audience={`${window.location.origin}/api`}
            scope={"read:packages"}
            skipRedirectCallback={window.location.search.indexOf("idp=") >=0 && window.location.search.indexOf("idp=default") == -1}
          >
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route
                path="/track"
                element={
                  <Suspense fallback={<Loader />}>
                    <Track />
                  </Suspense>
                }
              />
              <Route
                path="/packages"
                element={
                  <Suspense fallback={<Loader />}>
                    <Packages />
                  </Suspense>
                }
              />

              {/* <Route path="/management" element={<Track />} /> */}
            </Routes>
          </Auth0ProviderWithRedirectCallback>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
