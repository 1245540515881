import React from "react";
import { Navigate, useLocation, useQuery } from "react-router-dom";

const Home = () => {
  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  return <Navigate to={`/${query.get("route") || "track"}${search}`} replace />;
};

export default Home;
