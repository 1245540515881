import React from "react";
import LinearProgress from "@mui/material/LinearProgress";

const Loader = () => {
  return (
    <div style={{ width: "100%", height: "8px" }}>
      <LinearProgress style={{ height: "100%" }} />
    </div>
  );
};

export default Loader;
